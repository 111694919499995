import React from 'react';
import PropTypes from 'prop-types';
import AnchorLink from 'react-anchor-link-smooth-scroll';

export const AboutNav = ({ links, navId }) => (
  <nav className="local-nav" role="navigation">
    <ul className="local-nav__list">
      {links.map(link => (
        <li className="local-nav__item" key={link.anchor}>
          <AnchorLink
            className={`local-nav__link ${
              link.anchor === navId ? 'is-active' : ''
            }`}
            href={`#${link.anchor}`}
          >
            {link.linkText}
          </AnchorLink>
        </li>
      ))}
    </ul>
  </nav>
);

AboutNav.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      anchor: PropTypes.string.isRequired,
      linkText: PropTypes.string.isRequired,
    })
  ).isRequired,
  navId: PropTypes.string.isRequired,
};

/* eslint-disable react/no-danger, no-underscore-dangle */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { LayoutAbout } from '../components/layoutAbout';
import SEO from '../components/seo';
import { AboutNav } from '../components/aboutNav';
import { Image } from '../components/patterns/image';

const AboutPage = ({ data }) => {
  const sectionsData = data.neueamsterdam.page.aboutPage.aboutSections;

  return (
    <LayoutAbout>
      <SEO title="About" />
      <div className="about">
        {sectionsData.map(section => (
          <Fragment key={section.navId}>
            <article
              className="about__section"
              key={section.headerImageSmall1x.sourceUrl}
            >
              <span className="about__anchor" id={section.navId} />
              <section className="case-study__hero-image">
                <div className="container">
                  <AboutNav links={section.navLinks} navId={section.navId} />
                </div>
                <Image
                  alt={section.altText}
                  srcSmall={section.headerImageSmall1x.sourceUrl}
                  srcSmall2x={
                    section.headerImageSmall2x &&
                    section.headerImageSmall2x.sourceUrl
                  }
                  srcLarge={section.headerImageLarge1x.sourceUrl}
                  srcLarge2x={
                    section.headerImageSmallLarge2x &&
                    section.headerImageSmallLarge2x.sourceUrl
                  }
                  lazyLoad
                />
              </section>
              <section
                className={`about__copy-section ${section.navId}`}
                dangerouslySetInnerHTML={{ __html: section.copy[0].text }}
              />
            </article>
          </Fragment>
        ))}
      </div>
    </LayoutAbout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.shape({
    neueamsterdam: PropTypes.shape({
      page: PropTypes.shape({
        aboutPage: PropTypes.shape({
          aboutSections: PropTypes.arrayOf(
            PropTypes.shape({
              altText: PropTypes.string.isRequired,
              copy: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
              copyBelowImage: PropTypes.bool,
              headerImageSmall1x: PropTypes.shape({
                sourceUrl: PropTypes.string.isRequired,
              }).isRequired,
              headerImageSmall2x: PropTypes.shape({
                sourceUrl: PropTypes.string,
              }),
              headerImageLarge1x: PropTypes.shape({
                sourceUrl: PropTypes.string.isRequired,
              }).isRequired,
              headerImageLarge2x: PropTypes.shape({
                sourceUrl: PropTypes.string,
              }),
            })
          ),
        }),
      }),
    }),
  }).isRequired,
};

export const query = graphql`
  query {
    neueamsterdam {
      page(id: 1227, idType: DATABASE_ID) {
        aboutPage {
          aboutSections {
            navId
            navLinks {
              anchor
              linkText
            }
            altText
            copy {
              ... on NeueAmsterdam_Page_Aboutpage_aboutSections_Copy_PlainContent {
                text
              }
            }
            headerImageLarge2x {
              sourceUrl
            }
            headerImageLarge1x {
              sourceUrl
            }
            headerImageSmall2x {
              sourceUrl
            }
            headerImageSmall1x {
              sourceUrl
            }
          }
        }
      }
    }
  }
`;

export default AboutPage;

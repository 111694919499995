import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import '../styles/main.scss';

import { HeaderAbout } from './headerAbout';
import { Footer } from './footer';

export const LayoutAbout = ({ children }) => (
  <Fragment>
    <a className="skip-link" href="#main">
      skip to main content
    </a>
    <HeaderAbout />
    <main className="main" id="main" role="main">
      {children}
    </main>
    <Footer />
  </Fragment>
);

LayoutAbout.propTypes = {
  children: PropTypes.node.isRequired,
};
